
.container[data-v-9fdbb837] {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0;
}
[data-v-9fdbb837] .toggle-btn {
	display: none;
}
[data-v-9fdbb837] .tools {
	padding-top: 0 !important;
}
.title[data-v-9fdbb837] {
	height: 75px;
	line-height: 75px;
	margin-bottom: 0;
}
