.attendance[data-v-b3e8cbdd] {
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
}
.type[data-v-b3e8cbdd] {
  display: inline-block;
  margin-right: 16px;
  font-size: 12px;
  color: #000;
}
.toolbar[data-v-b3e8cbdd] {
  padding: 8px 0;
}
.refresh[data-v-b3e8cbdd] {
  float: right;
}
